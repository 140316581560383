// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAll = createAsyncThunk('vehicles/getAll', async () => {
  const response = await axios.get('/vehicles')
  return response.data
})

export const getStatistics = createAsyncThunk('vehicles/getStatistics', async () => {
  const response = await axios.get('/dashboard')
  return response.data
})

export const getVehicle = createAsyncThunk('vehicles/getVehicle', async (id) => {
  const response = await axios.get(`/vehicles/${id}`)
  return response.data
})

export const getUsages = createAsyncThunk('vehicles/getUsages', async (id) => {
  const response = await axios.get(`/usages/${id}`)
  return response.data
})

export const getMaintenances = createAsyncThunk('vehicles/getMaintenances', async (id) => {
  const response = await axios.get(`/maintenances/${id}`)
  return response.data
})

export const getChart = createAsyncThunk('vehicles/getChart', async (id) => {
  const response = await axios.get(`/dashboard/${id}`)
  return response.data
})

export const addData = createAsyncThunk(
  'vehicles/addData',
  async (vehicle, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/vehicles', vehicle)
      await dispatch(getAll())
      await dispatch(getStatistics())
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Mengambil pesan kesalahan dari respons JSON jika status kode 409
        const errorMessage = error.response.data
        return rejectWithValue(errorMessage)
      } else {
        throw error // Melanjutkan melempar kesalahan untuk penanganan umum
      }
    }
  }
)

export const addDataUsages = createAsyncThunk(
  'vehicles/addDataUsages',
  async (usage, { dispatch }) => {
    const response = await axios.post('/usages', usage)
    await dispatch(getAll())
    await dispatch(getStatistics())
    return response.data
  }
)

export const addDataReturn = createAsyncThunk(
  'vehicles/addDataReturn',
  async (usage, { dispatch }) => {
    const response = await axios.patch('/usages', usage)
    await dispatch(getAll())
    await dispatch(getStatistics())
    return response.data
  }
)

export const addMaintenances = createAsyncThunk(
  'vehicles/addMaintenances',
  async (maintenance, { dispatch }) => {
    const response = await axios.post('/maintenances', maintenance)
    await dispatch(getAll())
    await dispatch(getStatistics())
    return response.data
  }
)

export const getAllJeniskendaraan = createAsyncThunk('vehicles/getAllJeniskendaraan', async () => {
  const response = await axios.get('/jeniskendaraan')
  return response.data
})

export const getAllFuels = createAsyncThunk('vehicles/getAllFuels', async () => {
  const response = await axios.get('/fuels')
  return response.data
})

export const updateData = createAsyncThunk(
  'vehicles/updateData',
  async (vehicle, { dispatch }) => {
    const response = await axios.put(`/vehicles/${vehicle.id}`, vehicle)
    await dispatch(getAll())
    await dispatch(getStatistics())
    return response.data
  }
)

export const deleteData = createAsyncThunk(
  'vehicles/deleteData',
  async ({ id }, { dispatch }) => {
    const response = await axios.delete(`/vehicles/${id}`)
    await dispatch(getAll())
    await dispatch(getStatistics())
    return response.data
  })

export const getFile = createAsyncThunk('vehicles/getFile', async (file) => {
  const response = await axios.post(`/usages/file`, file, { responseType: 'blob' })
  const fileData = response.data
  // Buat objek URL untuk file
  const fileUrl = URL.createObjectURL(fileData)
  return fileUrl
})

export const dataSlice = createSlice({
  name: 'vehicles',
  initialState: {
    results: [],
    result: {},
    usages: [],
    maintenances: [],
    statistics: [],
    chartData: [],
    file: null,
    selected: null,
    vehicleOptions: [],
    jenisOptions: [],
    fuelsOptions: [],
    isLoading: false
  },
  reducers: {
    selectData: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAll.pending, (state) => {
        state.isLoading = true
        state.vehicleOptions = []
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isLoading = false
        state.results = action.payload
        state.vehicleOptions = []
        action.payload.map(item => {
          state.vehicleOptions.push({ value: item.id, label: item.nopol })
        })
      })
      .addCase(getVehicle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getVehicle.fulfilled, (state, action) => {
        state.isLoading = false
        state.result = action.payload
      })
      .addCase(getUsages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUsages.fulfilled, (state, action) => {
        state.isLoading = false
        state.usages = action.payload
      })
      .addCase(getMaintenances.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMaintenances.fulfilled, (state, action) => {
        state.isLoading = false
        state.maintenances = action.payload
      })
      .addCase(getChart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getChart.fulfilled, (state, action) => {
        state.isLoading = false
        state.chartData = action.payload
      })
      .addCase(getStatistics.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStatistics.fulfilled, (state, action) => {
        state.isLoading = false
        state.statistics = action.payload
      })
      .addCase(addData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addData.rejected, (state, action) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Failed!',
          html: `Tambah data gagal.<br>${action.payload.msg}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(addData.fulfilled, (state) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Success!',
          text: 'Tambah data berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(addDataUsages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addDataUsages.rejected, (state) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Failed!',
          text: 'Tambah data pemakaian gagal',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(addDataUsages.fulfilled, (state) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Success!',
          text: 'Tambah data pemakaian berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(addDataReturn.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addDataReturn.rejected, (state) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Failed!',
          text: 'Tambah data pemakaian gagal',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(addDataReturn.fulfilled, (state) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Success!',
          text: 'Tambah data pemakaian berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(addMaintenances.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addMaintenances.rejected, (state) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Failed!',
          text: 'Tambah data perawatan gagal',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(addMaintenances.fulfilled, (state) => {
        state.isLoading = false
        MySwal.fire({
          title: 'Success!',
          text: 'Tambah data perawatan berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(updateData.rejected, () => {
        MySwal.fire({
          title: 'Failed!',
          text: 'Ubah data gagal',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(updateData.fulfilled, () => {
        MySwal.fire({
          title: 'Success!',
          text: 'Ubah data berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(deleteData.rejected, () => {
        MySwal.fire({
          title: 'Failed!',
          text: 'Hapus data gagal',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(deleteData.fulfilled, () => {
        MySwal.fire({
          title: 'Success!',
          text: 'Hapus data berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(getAllJeniskendaraan.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllJeniskendaraan.fulfilled, (state, action) => {
        state.isLoading = false
        state.jenisOptions = []
        action.payload.map(item => {
          state.jenisOptions.push({ value: item.id, label: item.nama })
        })
      })
      .addCase(getAllFuels.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllFuels.fulfilled, (state, action) => {
        state.isLoading = false
        state.fuelsOptions = []
        action.payload.map(item => {
          state.fuelsOptions.push({ value: item.id, label: item.nama })
        })
      })
      .addCase(getFile.pending, (state) => {
        state.file = null
        state.isLoading = true
      })
      .addCase(getFile.fulfilled, (state, action)  => {
        state.isLoading = false
        state.file = action.payload
      })
  }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
