// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAll = createAsyncThunk('fuels/getAll', async () => {
    const response = await axios.get('/fuels')
    return response.data
})

export const addData = createAsyncThunk(
    'fuels/addData',
    async (fuel, { dispatch }) => {
        const response = await axios.post('/fuels', fuel)
        await dispatch(getAll())
        return response.data
    }
)

export const updateData = createAsyncThunk(
    'fuels/updateData',
    async (fuel, { dispatch }) => {
        const response = await axios.put(`/fuels/${fuel.id}`, fuel)
        await dispatch(getAll())
        return response.data
    }
)

export const deleteData = createAsyncThunk(
    'fuels/deleteData',
    async ({ id }, { dispatch }) => {
        const response = await axios.delete(`/fuels/${id}`)
        await dispatch(getAll())
        return response.data
    })


export const dataSlice = createSlice({
    name: 'fuels',
    initialState: {
        results: [],
        result: null,
        selected: null,
        isLoading: false
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAll.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAll.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(addData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Tambah data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Tambah data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(updateData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Ubah data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(updateData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Ubah data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Hapus data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Hapus data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
    }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
