// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAll = createAsyncThunk('vouchers/getAll', async () => {
    const response = await axios.get('/vouchers')
    return response.data
})

export const getFile = createAsyncThunk('vouchers/getFile', async (file) => {
  const response = await axios.post(`/vouchers/file`, file, { responseType: 'blob' })
  const fileData = response.data
  // Buat objek URL untuk file
  const fileUrl = URL.createObjectURL(fileData)
  return fileUrl
})


export const dataSlice = createSlice({
    name: 'vouchers',
    initialState: {
        results: [],
        selected: null,
        isLoading: false,
        file: null
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAll.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAll.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getFile.pending, (state) => {
          state.file = null
          state.isLoading = true
        })
        .addCase(getFile.fulfilled, (state, action)  => {
          state.isLoading = false
          state.file = action.payload
        })
    }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
