// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import user from '@src/views/users/store'
import jeniskendaraan from '@src/views/jeniskendaraan/store'
import fuels from '@src/views/fuels/store'
import vehicles from '@src/views/vehicles/store'
import vouchers from '@src/views/vouchers/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  user,
  jeniskendaraan,
  fuels,
  vehicles,
  vouchers
}

export default rootReducer
